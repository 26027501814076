import {Skeleton} from "@nextui-org/skeleton";

export default function RolesListLoading() {
    return (
        <div className="w-100 content-center min-h-dvh">
            <div className="mx-auto w-[200px] space-y-5 p-4">
                <Skeleton className="rounded-lg" disableAnimation={false}>
                    <div className="h-24 rounded-lg bg-default-300"></div>
                </Skeleton>
                <div className="space-y-3">
                    <Skeleton className="w-3/5 rounded-lg">
                        <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                    </Skeleton>
                    <Skeleton className="w-4/5 rounded-lg">
                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                    </Skeleton>
                    <Skeleton className="w-2/5 rounded-lg">  
                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                    </Skeleton>
                </div>
            </div>
        </div>
    );
}