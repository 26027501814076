import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface Company {
  uuid: string
  name: string
}

export interface Title {
  uuid: string
  name: string
}

export interface Industry {
  uuid: string
  name: string
}

export interface TechSkill {
  uuid: string
  name: string
}

export interface Role {
  uuid: string
  dateCreated: Date
  dateModified: Date
  company: Company
  title: Title
  industry: Industry
  techSkills: TechSkill[]
  startDate: string
  endDate: string
  workLocation: string
  whatDidYouBuild: string
  impactToTheBusiness: string
  teamSizeAndRole: string
  techEnvironment: string
}

export interface CandidatesSliceState {
  roles: Role[]
  getRolesStatus: "idle" | "loading" | "failed"
  addRoleStatus: "idle" | "loading" | "failed"
  editRoleStatus: "idle" | "loading" | "failed"
  deleteRoleStatus: "idle" | "loading" | "failed"
  companies: Company[],
  getCompaniesStatus: "idle" | "loading" | "failed",
  titles: Title[],
  getTitlesStatus: "idle" | "loading" | "failed",
  industries: Industry[],
  getIndustriesStatus: "idle" | "loading" | "failed",
  techSkills: TechSkill[],
  getTechSkillsStatus: "idle" | "loading" | "failed"
}

const initialState: CandidatesSliceState = {
  roles: [],
  getRolesStatus: 'idle',
  addRoleStatus: 'idle',
  editRoleStatus: 'idle',
  deleteRoleStatus: 'idle',
  companies: [],
  getCompaniesStatus: 'idle',
  titles: [],
  getTitlesStatus: 'idle',
  industries: [],
  getIndustriesStatus: 'idle',
  techSkills: [],
  getTechSkillsStatus: 'idle'
};

export const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getRoles.pending, (state) => {
      state.getRolesStatus = 'loading'
    })
    .addCase(getRoles.fulfilled, (state, action) => {
      state.getRolesStatus = 'idle'
      state.roles = action.payload
    })
    .addCase(getRoles.rejected, (state) => {
      state.getRolesStatus = 'failed' 
    })
    .addCase(addRole.pending, (state) => {
      state.addRoleStatus = 'loading'
    })
    .addCase(addRole.fulfilled, (state, action) => {
      state.addRoleStatus = 'idle'
      state.roles = [...state.roles, action.payload]
    })
    .addCase(addRole.rejected, (state) => {
      state.addRoleStatus = 'failed' 
    })
    .addCase(editRole.pending, (state) => {
      state.editRoleStatus = 'loading'
    })
    .addCase(editRole.fulfilled, (state, action) => {
      state.editRoleStatus = 'idle'
      var roles = state.roles.slice()
      roles = roles.map(x => x.uuid !== action.payload.uuid ? x : action.payload)
      state.roles = roles
    })
    .addCase(editRole.rejected, (state) => {
      state.editRoleStatus = 'failed' 
    })
    .addCase(deleteRole.pending, (state) => {
      state.deleteRoleStatus = 'loading'
    })
    .addCase(deleteRole.fulfilled, (state, action) => {
      state.deleteRoleStatus = 'idle'
      var roles = state.roles.slice()
      roles = roles.filter(x => x.uuid !== action.payload.uuid)
      state.roles = roles
    })
    .addCase(deleteRole.rejected, (state) => {
      state.deleteRoleStatus = 'failed' 
    })
    .addCase(getCompanies.pending, (state) => {
      state.getCompaniesStatus = 'loading'
    })
    .addCase(getCompanies.fulfilled, (state, action) => {
      state.getCompaniesStatus = 'idle'
      state.companies = action.payload
    })
    .addCase(getCompanies.rejected, (state) => {
      state.getCompaniesStatus = 'failed' 
    })
    .addCase(getTitles.pending, (state) => {
      state.getTitlesStatus = 'loading'
    })
    .addCase(getTitles.fulfilled, (state, action) => {
      state.getTitlesStatus = 'idle'
      state.titles = action.payload
    })
    .addCase(getTitles.rejected, (state) => {
      state.getTitlesStatus = 'failed' 
    })
    .addCase(getIndustries.pending, (state) => {
      state.getIndustriesStatus = 'loading'
    })
    .addCase(getIndustries.fulfilled, (state, action) => {
      state.getIndustriesStatus = 'idle'
      state.industries = action.payload
    })
    .addCase(getIndustries.rejected, (state) => {
      state.getIndustriesStatus = 'failed' 
    })
    .addCase(getTechSkills.pending, (state) => {
      state.getTechSkillsStatus = 'loading'
    })
    .addCase(getTechSkills.fulfilled, (state, action) => {
      state.getTechSkillsStatus = 'idle'
      state.techSkills = action.payload
    })
    .addCase(getTechSkills.rejected, (state) => {
      state.getTechSkillsStatus = 'failed' 
    });
  },
});

export const getRoles = createAsyncThunk(
  'candidates/getRoles',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_CANDIDATES_API_URL}candidates/${data.uuid}/roles`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)


export const addRole = createAsyncThunk(
  'candidates/addRole',
  async (data: any, thunkAPI) => {
    data.payload.profileId = data.uuid;
    const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}candidates/${data.uuid}/roles`, {
      method: "POST",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const editRole = createAsyncThunk(
  'candidates/editRole',
  async (data: any, thunkAPI) => {
    data.payload.profileId = data.uuid;
    const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}candidates/${data.uuid}/roles/${data.payload.uuid}`, {
      method: "PUT",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const deleteRole = createAsyncThunk(
  'candidates/deleteRole',
  async (data: any, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}candidates/${data.profileId}/roles/${data.uuid}`, {
      method: "DELETE",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const getCompanies = createAsyncThunk(
  'candidates/getCompanies',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_CANDIDATES_API_URL}candidates/companies`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const getTitles = createAsyncThunk(
  'candidates/getTitles',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_CANDIDATES_API_URL}candidates/titles`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const getIndustries = createAsyncThunk(
  'candidates/getIndustries',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_CANDIDATES_API_URL}candidates/industries`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const getTechSkills = createAsyncThunk(
  'candidates/getTechSkills',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_CANDIDATES_API_URL}candidates/tech-skills`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)


export default candidatesSlice.reducer;
