import { configureStore } from '@reduxjs/toolkit';
import candidatesReducer from '../features/candidates/candidatesSlice';
import profilesReducer from '../features/profiles/profilesSlice';

export const store = configureStore({
    reducer: {
        candidates: candidatesReducer,
        profiles: profilesReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})
  
  
// export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>


// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch