
import React from "react";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";
import { Navigation } from "../../common/navigation";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../common/icons/homeIcon";
import RolesIcon from "../common/rolesIcon";
import CandidateRoleForm from "./form";

export default function AddCandidateRoleForm() {

    let navigate = useNavigate()
    
    return (

        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <Breadcrumbs className="pt-3 mb-12">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/roles')}><RolesIcon />My roles</BreadcrumbItem>
                    <BreadcrumbItem>Add role</BreadcrumbItem>
                </Breadcrumbs>

                <CandidateRoleForm 
                    type="add"
                    defaultValues={{
                        startDate: undefined,
                        endDate: undefined,
                        stillWorkingHere: false,
                        company: '',
                        title: '',
                        industry: '',
                        techSkills: '',
                        workLocation: '',
                        whatDidYouBuild: '',
                        impactToTheBusiness: '',
                        teamSizeAndRole: '',
                        techEnvironment: ''
                    }}
                />

            </div>
        </div>

    );
}