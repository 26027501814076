import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Title } from "../candidates/candidatesSlice";

export interface CandidateProfile {
  dateCreated: string
  description: string
  idealWorkLocation: any
  linkedInUrl: string
  gitHubUrl: string
  inducted: boolean
  currentDayRate: number
  idealPosition: Title
  idealWorkType: string
  idealDayRate: number
  noticePeriod: string
}

export interface Profile {
  uuid: string
  dateCreated: string
  invited: boolean
  completed: boolean
  candidateProfile: CandidateProfile
}

export enum UserType {
  Candidate,
  Company
}

export interface ProfilesSliceState {
  geolocation: any | null
  getGeolocationStatus: "idle" | "loading" | "failed"
  profile: Profile | null
  getProfileStatus: "idle" | "loading" | "failed"
  userType: UserType | null
  selectUserTypeStatus: "idle" | "loading" | "failed"
  enterInvitationCodeStatus: "idle" | "loading" | "failed"
  updateProfileStatus: "idle" | "loading" | "failed"
}

const initialState: ProfilesSliceState = {
  geolocation: null,
  getGeolocationStatus: 'idle',
  profile: null,
  getProfileStatus: 'idle',
  userType: null,
  selectUserTypeStatus: 'idle',
  enterInvitationCodeStatus: 'idle',
  updateProfileStatus: 'idle',
};

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeolocation.pending, (state) => {
        state.getGeolocationStatus = 'loading'
        state.geolocation = null
      })
      .addCase(getGeolocation.fulfilled, (state, action) => {
        state.getGeolocationStatus = 'idle'
        state.geolocation = action.payload
      })
      .addCase(getGeolocation.rejected, (state) => {
        state.getGeolocationStatus = 'failed' 
        state.geolocation = null
      })
      .addCase(getProfile.pending, (state) => {
        state.getProfileStatus = 'loading'
        state.profile = null
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileStatus = 'idle'
        state.profile = action.payload
      })
      .addCase(getProfile.rejected, (state) => {
        state.getProfileStatus = 'failed' 
        state.profile = null
      })
      .addCase(selectUserType.pending, (state) => {
        state.selectUserTypeStatus = 'loading'
        state.userType = null
      })
      .addCase(selectUserType.fulfilled, (state, action) => {
        state.selectUserTypeStatus = 'idle'
        state.userType = (action.payload && action.payload.userType) ? action.payload.userType : null
      })
      .addCase(selectUserType.rejected, (state) => {
        state.selectUserTypeStatus = 'failed' 
        state.userType = null
      })
      .addCase(enterInvitationCode.pending, (state) => {
        state.enterInvitationCodeStatus = 'loading'
      })
      .addCase(enterInvitationCode.fulfilled, (state, action) => {
        state.enterInvitationCodeStatus = 'idle'
        var profile = state.profile
        profile!.invited = true
        state.profile = profile
      })
      .addCase(enterInvitationCode.rejected, (state) => {
        state.enterInvitationCodeStatus = 'failed' 
      })
      .addCase(updateProfile.pending, (state) => {
        state.updateProfileStatus = 'loading'
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.updateProfileStatus = 'idle'
        state.profile = action.payload
      })
      .addCase(updateProfile.rejected, (state) => {
        state.updateProfileStatus = 'failed' 
      })
      .addCase(inductProfile.pending, (state) => {
        state.updateProfileStatus = 'loading'
      })
      .addCase(inductProfile.fulfilled, (state, action) => {
        state.updateProfileStatus = 'idle'
        state.profile = action.payload
      })
      .addCase(inductProfile.rejected, (state) => {
        state.updateProfileStatus = 'failed' 
      });
  },
})

export const getGeolocation = createAsyncThunk(
  'profiles/getGeolocation',
  async (thunkAPI) => {
      return await fetch('https://ipapi.co/json/').then( res => res.json())
  }
)

export const getProfile = createAsyncThunk(
  'profiles/getProfile',
  async (data: any, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}profiles/by-user/${data.uuid}`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const selectUserType = createAsyncThunk(
  'profiles/selectUserType',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}profiles/${data.uuid}`, {
      method: "PATCH",
      body: JSON.stringify({ uuid: data.uuid, userType: data.type}),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const enterInvitationCode = createAsyncThunk(
  'profiles/enterInvitationCode',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}profiles/invitation-codes/${data.uuid}`, {
      method: "PUT",
      body: JSON.stringify({uuid: data.uuid, userId: data.userId}),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const updateProfile = createAsyncThunk(
  'profiles/updateProfile',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}profiles/${data.uuid}`, {
      method: "PATCH",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const inductProfile = createAsyncThunk(
  'profiles/inductProfile',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_PROFILES_API_URL}profiles/${data.uuid}/induct`, {
      method: "PUT",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export default profilesSlice.reducer;
