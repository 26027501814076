import {parsePhoneNumber} from "libphonenumber-js";
import { formatPhoneNumberIntl } from "react-phone-number-input";

export const shouldInternationalisePhoneNumber = (number: string) : boolean  => {

    if(!number || number.indexOf('+') > -1) {
        return false
    } else {
        return true
    }
}


export const internationalisePhoneNumber = (number: string, geolocation: any) : string  => {

    if(!shouldInternationalisePhoneNumber(number)) {
        return number
    }

    if(!geolocation) {
        return number
    }

    try {
        var international = parsePhoneNumber(number, geolocation.country_code)
        return international.number
    } catch(e) {
        return number
    }
}

export const internationalisePhoneNumberForDisplay = (number: string, geolocation: any) : string  => {

    if(!shouldInternationalisePhoneNumber(number)) {
        return formatPhoneNumberIntl(number)
    }

    return formatPhoneNumberIntl(internationalisePhoneNumber(number, geolocation))
}

