import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { getProfile } from "../../../features/profiles/profilesSlice";
import { useEffect } from "react";
import { Navigation } from "../common/navigation";
import AuthLoading  from "../common/authLoading";
import { BreadcrumbItem, Breadcrumbs, Card, CardBody, CardHeader, Progress } from "@nextui-org/react";
import moment from 'moment';
import HomeIcon from "../common/icons/homeIcon";
import { useNavigate } from "react-router-dom";
import EditIcon from "../common/icons/editIcon";
import ProfileIcon from "../common/icons/profileIcon";
import MyProfileError from "./error";
import MyProfileLoading from "./loading";
import StarIcon from "../common/icons/starIcon";
import { calculatePercentageComplete, isValidUrl, normaliseUrl, translateNoticePeriod, translateWorkType } from "../../../lib/utils";
import { internationalisePhoneNumberForDisplay } from "../../../lib/phoneNumber";
import StarOutlineIcon from "../common/icons/starOutlineIcon";

export default function MyProfile() {

    const { keycloak, initialized } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const geolocation = useAppSelector(state => state.profiles.geolocation)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    const loaded = () => { return getProfileStatus === 'idle' }
    const loading = () => { return getProfileStatus === 'loading'}
    const failed = () => { return getProfileStatus === 'failed' }

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {
        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    return initialized && keycloak.authenticated ? (

      <React.Fragment>
        {loading() && (
          <MyProfileLoading />
        )}
        {failed() && (
          <MyProfileError />
        )}
        {loaded() && profile && (
      
          <div className="bg-zinc-50 min-h-screen">
            <Navigation />

            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
                <Breadcrumbs className="mt-3 mb-11">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem><ProfileIcon />My profile</BreadcrumbItem>
                </Breadcrumbs>
 
                <div className="flex justify-between">

                  <div className="w-1/3">
                    <Card shadow="none">
                      <CardHeader className="px-6 pt-4 pb-0 font-semibold justify-between">
                        {keycloak.tokenParsed?.given_name} {keycloak.tokenParsed?.family_name}
                        <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/edit-my-profile')}>
                          <EditIcon />
                        </span>
                      </CardHeader>
                      <CardBody className="p-6">

                        <span className="bg-slate-100 p-4 mt-4 mb-10 rounded-md">
                          <span className="text-sm justify-between flex">
                            <span>Your profile is {calculatePercentageComplete(profile)}% complete</span>
                            {calculatePercentageComplete(profile) === 100 && <StarIcon fill='gold' />}
                            {calculatePercentageComplete(profile) < 100 && <StarOutlineIcon />}
                          </span>
                          <Progress size="sm" aria-label="Profile completeness" value={calculatePercentageComplete(profile)} className="mt-4"/>
                        </span>

                        <span className="text-slate-500">Email</span>
                        <span className="pb-2">{keycloak.tokenParsed?.email}</span>

                        <span className="text-slate-500">Phone</span>
                        <span className="pb-2">{internationalisePhoneNumberForDisplay(keycloak.tokenParsed?.phone_number, geolocation)}</span>

                        <span className="text-slate-500">Ideal work location</span>
                        <span className="pb-2">{profile?.candidateProfile?.idealWorkLocation?.formatted}</span>

                        <span className="text-slate-500">Date joined</span>
                        <span className="pb-2">{moment(profile?.dateCreated).format('Do MMMM, YYYY')}</span>

                        {profile?.candidateProfile?.linkedInUrl && ( 
                            <>
                              <span className="text-slate-500">LinkedIn</span>
                              <span className="pb-2">
                                {isValidUrl(profile?.candidateProfile?.linkedInUrl) && (<a className="link-primary" href={normaliseUrl(profile?.candidateProfile?.linkedInUrl)} target="_blank" rel="noreferrer">{profile?.candidateProfile?.linkedInUrl}</a>)}
                                {!isValidUrl(profile?.candidateProfile?.linkedInUrl) && (<span>{profile?.candidateProfile?.linkedInUrl}</span>)}
                              </span>
                            </>
                        )}
                        {profile?.candidateProfile?.gitHubUrl && ( 
                            <>
                              <span className="text-slate-500">GitHub</span>
                              <span className="pb-2">
                                {isValidUrl(profile?.candidateProfile?.gitHubUrl) && (<a className="link-primary" href={normaliseUrl(profile?.candidateProfile?.gitHubUrl)} target="_blank" rel="noreferrer">{profile?.candidateProfile?.gitHubUrl}</a>)}
                                {!isValidUrl(profile?.candidateProfile?.gitHubUrl) && (<span>{profile?.candidateProfile?.gitHubUrl}</span>)}
                              </span>
                            </>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                  <div className="pl-10 w-2/3">
                    <Card shadow="none" className="mb-10">
                      <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                        About me
                        <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/edit-my-profile')}>
                          <EditIcon />
                        </span>
                      </CardHeader>
                      <CardBody className="p-6">
                        <span>{profile?.candidateProfile?.description}</span>
                      </CardBody>
                    </Card>

                    <Card shadow="none" className="mb-10">
                      <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                        My work
                        <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/edit-my-work')}>
                          <EditIcon />
                        </span>
                      </CardHeader>
                      <CardBody className="p-6">

                        <span className="text-slate-500">Current Day Rate (ex Super &amp; GST)</span>
                        {profile?.candidateProfile?.currentDayRate !== null && <span className="pb-2">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(profile?.candidateProfile?.currentDayRate)}</span>}
                        {profile?.candidateProfile?.currentDayRate === null && <span className="pb-2">Not provided</span>}

                        <span className="text-slate-500">Notice Period</span>
                        {profile?.candidateProfile?.noticePeriod != null && <span className="pb-2">{translateNoticePeriod(profile?.candidateProfile?.noticePeriod)}</span>}
                        {profile?.candidateProfile?.noticePeriod === null && <span className="pb-2">Not provided</span>}

                        <span className="text-slate-500 mt-8">Ideal Position</span>
                        {profile?.candidateProfile?.idealPosition && <span className="pb-2">{profile?.candidateProfile?.idealPosition?.name}</span>}
                        {!profile?.candidateProfile?.idealPosition && <span className="pb-2">Not provided</span>}

                        <span className="text-slate-500">Ideal Work Type</span>
                        {profile?.candidateProfile?.idealWorkType && <span className="pb-2">{translateWorkType(profile?.candidateProfile?.idealWorkType)}</span>}
                        {!profile?.candidateProfile?.idealWorkType && <span className="pb-2">Not provided</span>}
                        
                        <span className="text-slate-500">Ideal Day Rate (ex Super &amp; GST)</span>
                        {profile?.candidateProfile?.idealDayRate !== null && <span className="pb-2">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(profile?.candidateProfile?.idealDayRate)}</span>}
                        {profile?.candidateProfile?.idealDayRate === null && <span className="pb-2">Not provided</span>}

                      </CardBody>
                    </Card>
                  </div>

                </div>

            </div>
          </div>
        )}
      </React.Fragment>
    ) : <AuthLoading />
}
