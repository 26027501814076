import React from 'react';
import './App.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './app/pages/dashboard/dashboard';
import { NextUIProvider } from '@nextui-org/react';
import { RolesList } from './app/pages/candidates/roles/roles';
import AddCandidateRoleForm from './app/pages/candidates/roles/addRole';
import EditCandidateRoleForm from './app/pages/candidates/roles/editRole';
import MyProfile from './app/pages/profile/profile';
import EditProfileForm from './app/pages/profile/editProfile';
import EditWorkForm from './app/pages/profile/editWork';
import { AdminCandidatesList } from './app/pages/admin/candidates/candidates';
import { AdminViewCandidate } from './app/pages/admin/candidates/view';

function App() {

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <NextUIProvider>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/roles" element={<RolesList />} />
          <Route path="/add-role" element={<AddCandidateRoleForm />} />
          <Route path="/edit-role/:id" element={<EditCandidateRoleForm />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/edit-my-profile" element={<EditProfileForm />} />
          <Route path="/edit-my-work" element={<EditWorkForm />} />


          <Route path="/admin-candidates" element={<AdminCandidatesList />} />
          <Route path="/admin-view-candidate/:id" element={<AdminViewCandidate />} />
        </Routes>
      </NextUIProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
