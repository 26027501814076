
import {Button} from "@nextui-org/button"
import {Input, Textarea} from "@nextui-org/input"
import { Card } from "@nextui-org/card"
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import { useKeycloak } from "@react-keycloak/web"
import { getGeolocation, getProfile, updateProfile } from "../../../features/profiles/profilesSlice"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Navigation } from "../common/navigation"
import { BreadcrumbItem, Breadcrumbs, Spinner } from "@nextui-org/react"
import HomeIcon from "../common/icons/homeIcon"
import { useNavigate } from "react-router-dom"
import ProfileIcon from "../common/icons/profileIcon"
import AuthLoading from "../common/authLoading"
import MyProfileError from "./error"
import MyProfileLoading from "./loading"
import RightArrowIcon from "../common/icons/rightArrowIcon"
import { SearchIcon } from "../common/icons/searchIcon"
import Autocomplete from "react-google-autocomplete";
import { transformPlace } from "../../../lib/utils"
import { internationalisePhoneNumber, shouldInternationalisePhoneNumber } from "../../../lib/phoneNumber"

export default function EditProfileForm() {

    const { keycloak, initialized } = useKeycloak()
    const dispatch = useAppDispatch()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const geolocation = useAppSelector(state => state.profiles.geolocation)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.userInfo) {
            if(shouldInternationalisePhoneNumber(keycloak.tokenParsed!.phone_number)) {
                dispatch(getGeolocation())
            }
    
            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])
      

    return initialized && keycloak.authenticated ? (
        <>
          {getProfileStatus === 'loading' && (
            <MyProfileLoading />
          )}
          {getProfileStatus === 'failed' && (
            <MyProfileError />
          )}
          {getProfileStatus === 'idle' && (!shouldInternationalisePhoneNumber(keycloak.tokenParsed!.phone_number) || geolocation) && profile && (
            <EditProfileFormActual />
          )}
        </>
    ) : <AuthLoading />
}

function EditProfileFormActual() {

    type FormFields = {
        firstName: string
        lastName: string
        phoneNumber: string
        email: string
        description: string
        idealWorkLocation: any
        linkedInUrl: string
        gitHubUrl: string
    }
      

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.profiles.updateProfileStatus)

    const geolocation = useAppSelector(state => state.profiles.geolocation)

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: {
            firstName: keycloak?.tokenParsed?.given_name,
            lastName: keycloak?.tokenParsed?.family_name,
            email: keycloak?.tokenParsed?.email,
            phoneNumber: internationalisePhoneNumber(keycloak?.tokenParsed?.phone_number, geolocation),
            description: profile?.candidateProfile?.description,
            idealWorkLocation: profile?.candidateProfile?.idealWorkLocation,
            linkedInUrl: profile?.candidateProfile?.linkedInUrl,
            gitHubUrl: profile?.candidateProfile?.gitHubUrl
        }
    })

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {
        dispatch(updateProfile({token: keycloak.token, uuid: profile?.uuid, payload: data}))
            .then((response) => {
                if(response.meta.requestStatus === 'fulfilled') {
                    navigate('/my-profile')  
                }
            })
    }
      
    return (
    
        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <Breadcrumbs className="pt-3 mb-11">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/my-profile')}><ProfileIcon />My profile</BreadcrumbItem>
                    <BreadcrumbItem>Edit profile</BreadcrumbItem>
                </Breadcrumbs>

                <Card className="p-8 mx-auto" shadow="sm">
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-row flex-wrap w-full justify-between">
                            <div className="w-full lg:w-6/12">

                                <div className="flex flex-wrap mb-3 lg:mb-6">
                                    <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                        <Input
                                            label="First name"
                                            placeholder="Enter your first name"
                                            isRequired={true}
                                            onClear={() => console.log("clear")}
                                            errorMessage={errors.firstName && "First name is required"}
                                            isInvalid={errors.firstName != null}
                                            {...register("firstName", {required: true})} />
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <Input
                                            label="Last name"
                                            placeholder="Enter your last name"
                                            isRequired={true}
                                            onClear={() => console.log("clear")}
                                            errorMessage={errors.lastName && "Last name is required"}
                                            isInvalid={errors.lastName != null}
                                            {...register("lastName", {required: true})} />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-3 lg:mb-6">
                                    <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                        <div className={`controller-wrapper bg-default-100 rounded-medium${errors?.phoneNumber ? ' error' : ''}`}>
                                            <label className="z-10 pointer-events-none subpixel-antialiased block text-foreground-500 cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-small">Phone number</label>
                                            <Controller
                                                control={control}
                                                name="phoneNumber"
                                                rules={{
                                                    validate: {
                                                        required: (value) => {
                                                            if (!value) return 'Phone number is required';
                                                            if (!isValidPhoneNumber(value)) return 'A valid phone number is required';
                                                        }
                                                    }
                                                }}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <PhoneInput
                                                        className="phone-input"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        defaultCountry="AU"
                                                        placeholder="Enter phone number"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors?.phoneNumber && <span className="controller-error">{errors.phoneNumber.message}</span>}
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <Input
                                            label="Email address"
                                            placeholder="Enter your email address"
                                            isRequired={true}
                                            isDisabled={true}
                                            onClear={() => console.log("clear")}
                                            errorMessage={errors.email && "Email address is required"}
                                            isInvalid={errors.email != null}
                                            {...register("email", {required: true})} />

                                    </div>
                                </div>
                                <div className="w-full">

                                    <Textarea
                                        label="About you"
                                        minRows={5}
                                        placeholder="Tell us about yourself"
                                        isRequired={true}
                                        onClear={() => console.log("clear")}
                                        className="ml-auto w-full mb-6 lg:mb-12"
                                        errorMessage={errors.description && "Description is required"}
                                        isInvalid={errors.description != null}
                                        {...register("description", {required: true})} />

                                </div>    
                            </div>
                            <div className="w-full pt-12 lg:pt-0 lg:pl-20 lg:w-6/12 flex-column">

                                <div className="w-full mb-6">

                                    <div className={`controller-wrapper bg-default-100 rounded-medium${errors?.idealWorkLocation ? ' error' : ''}`}>
                                        <label className="z-10 pointer-events-none subpixel-antialiased block text-foreground-500 cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-small">Ideal work Location</label>
                                        <Controller
                                            control={control}
                                            name="idealWorkLocation"
                                            rules={{
                                                validate: {
                                                    required: (value) => {
                                                        if (!value) return 'A valid location is required, please select from the list';
                                                    }
                                                }
                                            }}
                                            render={({ field: { value } }) => (

                                                <div className="flex">
                                                    <SearchIcon className="mt-1 mr-2 text-default-400" strokeWidth={2.5} size={14} />
                                                    <Autocomplete
                                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                        options={{
                                                            fields: ['geometry', 'name', 'formatted_address', 'types'],
                                                            types: ['country', 'locality', 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3']
                                                        }}
                                                        defaultValue={profile?.candidateProfile?.idealWorkLocation?.formatted ?? ''}
                                                        onPlaceSelected={(place) => {
                                                            setValue('idealWorkLocation', transformPlace(place), { shouldValidate: true, shouldTouch: true, shouldDirty: true})
                                                        }} 
                                                        onBlur={(event) => {
                                                            // If we arrived here by clicking a selection, ignore this
                                                            var pacContainers = document.getElementsByClassName('pac-container');
                                                            if(pacContainers.length === 0) {
                                                                return;
                                                            }
                                                            var pacContainer = pacContainers[0];
                                                            if(pacContainer && pacContainer.matches(':hover')) {
                                                                return
                                                            }
                
                                                            setValue('idealWorkLocation', null, { shouldTouch: true, shouldDirty: true})
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {errors?.idealWorkLocation && <span className="controller-error">{errors.idealWorkLocation?.message?.toString()}</span>}
                                </div>

                                <div className="w-full mb-6">
                                    <Input
                                        label="LinkedIn URL"
                                        placeholder="Enter your LinkedIn URL"
                                        isRequired={false}
                                        onClear={() => console.log("clear")}
                                        errorMessage={errors.linkedInUrl && "Valid URL required"}
                                        isInvalid={errors.linkedInUrl != null}
                                        {...register("linkedInUrl", {required: false})} />
                                </div>
                                <div className="w-full">
                                    <Input
                                        label="GitHub URL"
                                        placeholder="Enter your GitHub URL"
                                        isRequired={false}
                                        onClear={() => console.log("clear")}
                                        errorMessage={errors.gitHubUrl && "Valid URL required"}
                                        isInvalid={errors.gitHubUrl != null}
                                        {...register("gitHubUrl", {required: false})} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full pt-6">
                            <Button className="bg-transparent font-semibold py-2 px-4 pl-0 mr-6 hover:border-transparent" onClick={() => navigate('/my-profile')}>Cancel</Button>
                            <Button color="primary" type="submit">
                                Save profile
                                {status === 'loading' && <Spinner size="sm" color="default" />}
                                {status !== 'loading' && <RightArrowIcon />}
                            </Button>
                            {status === 'failed' && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                        </div>

                    </form>
                </Card>
            </div>
        </div>
    );
}