
import {Button} from "@nextui-org/button";
import { DatePicker} from "@nextui-org/date-picker";
import { Textarea} from "@nextui-org/input";
import {Select, SelectItem} from "@nextui-org/select";
import { DateValue, now, today, getLocalTimeZone} from "@internationalized/date";
import { Autocomplete, AutocompleteItem } from "@nextui-org/autocomplete";
import { Card } from "@nextui-org/card";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import {I18nProvider} from "@react-aria/i18n";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { addRole, editRole, getCompanies, getIndustries, getRoles, getTechSkills, getTitles, TechSkill } from "../../../../features/candidates/candidatesSlice";
import { Checkbox, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner, useDisclosure } from "@nextui-org/react";
import SelectLoading from "../../common/selectLoading";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import ChipPlusIcon from "../../common/icons/chipPlusIcon";
import { SearchIcon } from "../../common/icons/searchIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";

type FormFields = {
    startDate: DateValue | null | undefined
    endDate: DateValue | null | undefined
    stillWorkingHere: boolean
    company: string
    title: string
    industry: string
    techSkills: string
    workLocation: string
    whatDidYouBuild: string
    impactToTheBusiness: string
    teamSizeAndRole: string
    techEnvironment: string
}

export default function CandidateRoleForm(props: any) {

    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const { keycloak, initialized } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)

    const roles = useAppSelector(state => state.candidates.roles)
    const editRoleStatus = useAppSelector(state => state.candidates.editRoleStatus)
    const addRoleStatus = useAppSelector(state => state.candidates.addRoleStatus)

    const companies = useAppSelector(state => state.candidates.companies)
    const getCompaniesStatus = useAppSelector(state => state.candidates.getCompaniesStatus)

    const titles = useAppSelector(state => state.candidates.titles)
    const getTitlesStatus = useAppSelector(state => state.candidates.getTitlesStatus)

    const industries = useAppSelector(state => state.candidates.industries)
    const getIndustriesStatus = useAppSelector(state => state.candidates.getIndustriesStatus)

    const techSkills = useAppSelector(state => state.candidates.techSkills)
    const getTechSkillsStatus = useAppSelector(state => state.candidates.getTechSkillsStatus)

    const [ selectedTechSkills, setSelectedTechSkills ] = useState<TechSkill[]>([])
    const [ selectedTechSkillsTouched, setSelectedTechSkillsTouched ] = useState(false)
    const [ selectedTechSkill, setSelectedTechSkill ] = useState('')
    const {isOpen, onOpen, onOpenChange} = useDisclosure()

    const {
        control,
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: props.defaultValues
    })

    const stillWorkingHere = watch('stillWorkingHere')

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 
    
    useEffect(() => {
        if (keycloak.authenticated) {

            dispatch(getCompanies({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))

            if(titles.length === 0)
                dispatch(getTitles({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))

            if(industries.length === 0)
                dispatch(getIndustries({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))

            if(techSkills.length === 0) {
                dispatch(getTechSkills({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
    
            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
                    .then((response) => {
                        dispatch(getRoles({token: keycloak.token, uuid: response.payload.uuid}))
                })
            } else if(!roles || roles.length === 0) {
                dispatch(getRoles({token: keycloak.token, uuid: profile.uuid}))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    useEffect(() => {
        if(techSkills.length > 0 ) {
            populateTechSkills(props.defaultValues.techSkills)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [techSkills])

    const populateTechSkills = (selectedSkills: any) => {
        if(!selectedSkills) {
            return
        }
        setSelectedTechSkills(selectedSkills?.map((x: any) => techSkills.find((y: any) => y.uuid === x)))
    }

    const deselectTechSkill = (uuid: string) => {

        if(!selectedTechSkills) {
            return
        }

        const index = selectedTechSkills.findIndex(x => x['uuid'] === uuid)
        if (index > -1) {
            var selected = selectedTechSkills.slice()            
            selected.splice(index, 1);
            setSelectedTechSkills(selected)
            setSelectedTechSkillsTouched(true)
        }

    }

    const selectTechSkill = (uuid: string) => {

        if(!selectedTechSkills) {
            return
        }

        const index = selectedTechSkills.findIndex(x => x['uuid'] === uuid)
        if (index === -1) {
            const found = techSkills.find(x => x['uuid'] === uuid)
            if(found) {
                var selected = selectedTechSkills.slice()      
                selected.push(found)
                setSelectedTechSkills(selected)
            }
        }

    }

    const techSkillsValid = () : boolean => {
        return selectedTechSkills.length > 0
    }

    const techSkillsError = () : string => {
        return !techSkillsValid() ? 'You must select at least one skill' : ''
    }

    const processData = (data: any) : any => {

        data.techSkills = selectedTechSkills.map(x => x.uuid)

        var company = companies.find(x => x.name === data.company)
        if(!company) {
            company = companies.find(x => x.uuid === data.company)
        }
        if(company) {
            data.company = company.uuid
        } else {
            data.customCompany = data.company
            data.company = null
        }

        var title = titles.find(x => x.uuid === data.title)
        if(!title) {
            title = titles.find(x => x.name === data.title)
        }
        data.title = title ? title.uuid : null

        var industry = industries.find(x => x.uuid === data.industry)
        if(!industry) {
            industry = industries.find(x => x.name === data.industry)
        }
        data.industry = industry ? industry.uuid : null

        data.startDate = data.startDate?.toString()
        data.endDate = data.endDate?.toString()

        if(data.stillWorkingHere) {
            data.endDate = null
        }

        return data
    }

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {

        setSelectedTechSkillsTouched(true)

        if(!techSkillsValid()) {
            return
        }

        data = processData(data)

        if(props.type === 'add') {
            dispatch(addRole({token: keycloak.token, uuid: profile?.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        navigate('/roles')  
                    }
                })
        } else if(props.type === 'edit') {
            dispatch(editRole({token: keycloak.token, uuid: profile?.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        navigate('/roles')  
                    }
                })
    } else if(props.type === 'initial') {
            dispatch(addRole({token: keycloak.token, uuid: profile?.uuid, payload: data}))
        }
    }

    return (

        <Card className="p-8 mx-auto" shadow="sm">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row flex-wrap w-full justify-between">
                    <div className="w-full lg:w-7/12">

                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                <Controller
                                        control={control}
                                        name="startDate"
                                        defaultValue = {null}
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if (!value) return 'A valid start date is required'
                                                    if(value.compare(now(getLocalTimeZone())) > 0) {
                                                        return 'Start date cannot be in the future'
                                                    }
                                                },
                                                lessThan: (value) => {
                                                    var endDate = getValues().endDate
                                                    if(value != null && endDate != null && value!.compare(endDate!) >= 0) {
                                                        return 'Start date must be before end date'
                                                    }
                                                }
                                            }
                                        }}

                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <I18nProvider locale="en-AU">
                                                <DatePicker 
                                                    label="From" 
                                                    isRequired={true} 
                                                    showMonthAndYearPickers={true}
                                                    granularity="day" 
                                                    value={value}
                                                    maxValue={today(getLocalTimeZone())}
                                                    onChange={(event) => { onChange(event); }}
                                                    isInvalid={errors.startDate != null}
                                                    errorMessage={errors.startDate?.message}/>
                                            </I18nProvider>
                                        )}
                                />
                                    
                            </div>
                            <div className="w-full lg:w-6/12">

                                <Controller
                                    control={control}
                                    name="endDate"
                                    disabled={stillWorkingHere === true}
                                    defaultValue = {null}
                                    rules={{
                                        validate: {
                                            required: (value) => {
                                                if (!value && !getValues().stillWorkingHere) return 'A valid end date is required'
                                                if(value && value.compare(now(getLocalTimeZone())) > 0) {
                                                    return 'End date cannot be in the future'
                                                }
                                            }
                                        }
                                    }}

                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <I18nProvider locale="en-AU">
                                            <DatePicker 
                                                label="To" 
                                                showMonthAndYearPickers={true}
                                                isRequired={true} 
                                                granularity="day" 
                                                isDisabled={stillWorkingHere === true}
                                                value={value}
                                                maxValue={today(getLocalTimeZone())}
                                                onChange={(event) => { onChange(event); }}
                                                isInvalid={errors.endDate != null}
                                                errorMessage={errors.endDate?.message}/>
                                        </I18nProvider>
                                    )}
                                />

                                <Checkbox
                                    size="sm" 
                                    className="pt-4 pb-8 lg:pb-0 lg:pt-8"
                                    {...register("stillWorkingHere")}>
                                        I'm still working here
                                </Checkbox>

                            </div>
                        </div>
        
                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-4 lg:w-1/2 lg:mb-0 lg:pr-6">
                                {getCompaniesStatus === 'loading' && <SelectLoading />}
                                {getCompaniesStatus === 'idle' && (
                                    <Autocomplete                     
                                        allowsCustomValue
                                        label="Company"
                                        placeholder="Search for a company" 
                                        defaultItems={companies}
                                        isRequired={true}
                                        errorMessage="Please select a company"
                                        isInvalid={errors.company != null}
                                        {...register("company", {required: true})}>
                                        {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                    </Autocomplete>
                                )}
                            </div>
                            <div className="w-full lg:w-1/2">
                                {getTitlesStatus === 'loading' && <SelectLoading />}
                                {getTitlesStatus === 'idle' && (
                                    <Autocomplete                     
                                        label="Title"
                                        placeholder="Search for your title" 
                                        defaultItems={titles}
                                        isRequired={true}
                                        errorMessage="Please select a title"
                                        isInvalid={errors.title != null}
                                        {...register("title", {required: true})}>
                                        {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                    </Autocomplete>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                {getIndustriesStatus === 'loading' && <SelectLoading />}
                                {getIndustriesStatus === 'idle' && (
                                    <Autocomplete
                                        label="Industry"
                                        placeholder="Select your industry"
                                        errorMessage={errors.industry && "Industry is required"}
                                        isRequired={true}
                                        isInvalid={errors.industry != null}
                                        {...register("industry", {required: true})}>
                                        
                                        {industries.map((value) => (
                                            <SelectItem key={value.uuid}>
                                                {value.name}
                                            </SelectItem>
                                        ))}
                                    </Autocomplete>
                                )}
                            </div>
                            <div className="w-full lg:w-1/2">
                                <Select
                                    label="Work from X"
                                    placeholder="Select from where you are working"
                                    errorMessage={errors.workLocation && "Work from X is required"}
                                    isRequired={true}
                                    isInvalid={errors.workLocation != null}
                                    {...register("workLocation", {required: true})}>
                                    
                                    <SelectItem key="ON_SITE">On-site</SelectItem>
                                    <SelectItem key="REMOTE">Remote</SelectItem>
                                    <SelectItem key="HYBRID">Hybrid</SelectItem>
                                </Select>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-full mb-4">
                                {getTechSkillsStatus === 'loading' && <SelectLoading />}
                                {getTechSkillsStatus === 'idle' && (
                                    <div>
                                        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                                            <ModalContent>
                                                {(onClose) => (
                                                    <>
                                                    <ModalHeader className="flex flex-col gap-1">Add tech skill</ModalHeader>
                                                    <ModalBody>

                                                        <Autocomplete                     
                                                            aria-label="Search for a tech skill"
                                                            startContent={<SearchIcon className="text-default-400" strokeWidth={2.5} size={20} />}
                                                            placeholder="Search for a skill" 
                                                            defaultItems={techSkills.filter((x) => {
                                                                return selectedTechSkills.find((y) => y['uuid'] === x['uuid']) === undefined
                                                            })}
                                                            onSelectionChange={(value) => {
                                                                if(value) {
                                                                    setSelectedTechSkill(value.toString())
                                                                } else {
                                                                    setSelectedTechSkill('')
                                                                }
                                                            }}
                                                            
                                                            >
                                                            {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                                        </Autocomplete>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button  
                                                            isDisabled={selectedTechSkill === '' || selectedTechSkills?.find(x => x['uuid'] === selectedTechSkill) !== undefined}
                                                            color={selectedTechSkill !== '' && selectedTechSkills?.find(x => x['uuid'] === selectedTechSkill) === undefined ? 'primary' : 'default'}
                                                            onPress={(e) => {
                                                                if(!selectedTechSkills?.find(x => x['uuid'] === selectedTechSkill)) {
                                                                    // onClose()
                                                                    selectTechSkill(selectedTechSkill)
                                                                }
                                                            }}
                                                        >
                                                            Add skill
                                                        </Button>
                                                    </ModalFooter>
                                                    </>
                                                )}
                                            </ModalContent>
                                        </Modal>
                                        <label 
                                        className={`pl-2 mt-4 mb-6 z-10 pointer-events-none subpixel-antialiased block ${!selectedTechSkillsTouched || !techSkillsError() ? 'text-foreground-500' : 'text-danger'} cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-xs`}>Tech Skills</label>

                                        <div className="flex flex-wrap gap-2 pl-2 pb-4">

                                            {selectedTechSkills?.map((value: any) => (
                                                <Chip 
                                                    color="primary" 
                                                    className="mb-2"
                                                    key={value.uuid}
                                                    onClose={() => {
                                                        deselectTechSkill(value?.uuid)
                                                    }}
                                                >
                                                    {value?.name}
                                                </Chip>
                                            ))}
                                            <Chip 
                                                className="cursor-pointer" 
                                                color={undefined}
                                                onClick={() => {
                                                    setSelectedTechSkill('')
                                                    onOpen()
                                                }}
                                            >
                                                <span className="flex">Add skill <ChipPlusIcon /></span>
                                            </Chip>                        
                                            {selectedTechSkillsTouched && techSkillsError() && (
                                                <span className="form-error inline">{techSkillsError()}</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full pt-12 lg:pt-0 lg:pl-20 lg:w-5/12 flex-column">

                        <Textarea
                            label="What did you build?"
                            className="max-w mb-6"
                            isRequired={true}
                            isInvalid={errors.whatDidYouBuild != null}
                            errorMessage={errors.whatDidYouBuild && "What did you build is required"}
                            {...register("whatDidYouBuild", {required: true})}></Textarea>

                        <Textarea
                            label="How did this impact the business?"
                            className="max-w mb-6"
                            isRequired={true}
                            isInvalid={errors.impactToTheBusiness != null}
                            errorMessage={errors.impactToTheBusiness && "How did this impact the business?"}
                            {...register("impactToTheBusiness", {required: true})}></Textarea>

                        <Textarea
                            label="Team size, and your role with the team?"
                            className="max-w mb-6"
                            isRequired={true}
                            isInvalid={errors.teamSizeAndRole != null}
                            errorMessage={errors.teamSizeAndRole && "Team size, and your role"}
                            {...register("teamSizeAndRole", {required: true})}></Textarea>

                        <Textarea
                            label="What was the tech environment?"
                            className="max-w"
                            isRequired={true}
                            isInvalid={errors.techEnvironment != null}
                            errorMessage={errors.techEnvironment && "Tech environment is required"}
                            {...register("techEnvironment", {required: true})}></Textarea>
                    
                    </div>    
                </div>
                <div className="flex flex-wrap w-full pt-12">
                    {props.type !== 'initial' && <Button className="bg-transparent font-semibold py-2 px-4 pl-0 mr-6 hover:border-transparent" onClick={() => navigate('/roles')}>Cancel</Button>}
                    <Button color="primary" type="submit">
                        {props.type === 'add' ? 'Add role' : props.type === 'edit' ? 'Edit role' : 'Continue'}
                        {(addRoleStatus === 'loading' || editRoleStatus === 'loading') && <Spinner size="sm" color="default" />}  
                        {(addRoleStatus !== 'loading' && editRoleStatus !== 'loading') && <RightArrowIcon />}
                    </Button>
                    {(addRoleStatus === 'failed' || editRoleStatus === 'failed') && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                </div>
            </form>
        </Card>
    );
}