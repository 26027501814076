import { Button, Spinner } from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { inductProfile } from "../../../../features/profiles/profilesSlice";
import RightArrowIcon from "../../common/icons/rightArrowIcon";
import { platformName } from "../../../../lib/utils";

export default function InitialCandidateInduction() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.profiles.updateProfileStatus)

    const handleClick = () => {
        dispatch(inductProfile( {token: keycloak.token!, uuid: profile?.uuid}));
    }

    return (

        <div className="w-full min-h-screen bg-zinc-50 content-center">

            <div className="flex max-w-3xl mx-auto pt-12 flex-wrap space-x-0 content-center justify-center">
                <div className="w-100 pb-2 text-center">

                    <p className="pb-10">
                        Our mission is to remove unconscious bias in the recruitment process, so we do not use resumes. 
                    </p>

                    <p className="pb-10">
                        Through the {platformName()} platform, we can assess not only the qualifications and experience but also the soft skills and cultural fit of potential candidates... this not only improves the overall quality of hires but also fosters a more inclusive and equitable hiring environment. 
                    </p>

                    <h1 className="text-center text-lg font-bold w-full pb-6">Why {platformName()}?</h1>

                    <span className="block font-medium pb-2">
                        Candidates & clients are in control of the process
                    </span>
                    <span className="block font-medium pb-2">
                        Full transparency on pay & charge rates
                    </span>
                    <span className="block font-medium pb-2">
                        Get hired based on your skills & experience
                    </span>
                    <span className="block font-medium pb-12">
                        Become part of the world's first curated marketplace of software engineers
                    </span>
                    
                    <p className="pb-12">
                        We need ten minutes of your time to complete your profile,<br /> and we can then start matching you to your next role.
                    </p>

                </div>                

                <Button color="primary" type="submit" className="mt-10" onClick={() => {handleClick()}}>
                    Continue to your dashboard
                    {status === 'loading' && <Spinner size="sm" color="default" />} 
                    {status !== 'loading' && <RightArrowIcon />}
                </Button>
            </div>
        </div>
    )

};