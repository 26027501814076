export default function N14xLogo() {
    return (
      <svg  version="1.1" viewBox="0 0 96.134 41.209" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-1.7652 -52.35)">
        <g transform="matrix(.30911 0 0 .26458 1.147 51.46)">
          <rect x="2" y="3.365" width="311" height="155.75" fill="#008afc"/>
        </g>
        <g transform="matrix(.25167 0 0 .24997 5.1651 -112.08)">
          <rect x="108.32" y="662.34" width="155.75" height="155.75" fill="#fff"/>
        </g>
        <g transform="matrix(.26458 0 0 .26458 -133.23 -103.76)">
          <g transform="matrix(133.33,0,0,133.33,522.22,714.07)">
          <path d="m0.45-0.7v0.38l-0.27-0.38h-0.12v0.7h0.16v-0.38l0.27 0.38h0.12v-0.7z" fill="#fff"/>
          </g>
        </g>
        <g transform="matrix(.26458 0 0 .26458 -62.497 -103.63)">
          <g transform="matrix(133.33,0,0,133.33,522.22,714.07)">
          <path d="m0.53 0-0.179-0.254 0.173-0.246h-0.172l-0.087 0.124-0.087-0.124h-0.172l0.173 0.246-0.179 0.254h0.172l0.093-0.132 0.093 0.132z" fill="#fff"/>
          </g>
        </g>
        <g transform="matrix(.39277 0 0 .39277 -65.01 -246.18)">
          <g transform="matrix(89.818,0,0,89.818,253.83,843.95)">
          <path d="m0.193-0.7-0.183 0.066 0.039 0.137 0.124-0.034v0.531h0.16v-0.7z" fill="#008afc"/>
          </g>
          <g transform="matrix(89.818,0,0,89.818,290.03,843.95)">
          <path d="m0.57-0.26h-0.07v-0.15h-0.162v0.15h-0.146l0.223-0.44h-0.172l-0.223 0.44v0.148h0.318v0.112h0.162v-0.112h0.07z" fill="#008afc"/>
          </g>
        </g>
        </g>
     </svg>
  );
}
