import React from "react";

export default function AuthLoading() {
    return (
        <div className="w-100 content-center min-h-dvh">
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-32 mx-auto opacity-5" viewBox="0 0 77.786 59.892"><g transform="translate(-90.18 -137.982)"><path d="M90.312 167.747v-29.633H104.593l.07 11.576.068 11.575h22.49l.069-11.575.069-11.576h14.282V197.381h-14.288v-24.607H104.6v24.607H90.31z"/><ellipse cx="159.383" cy="189.844" rx="8.47" ry="7.917" /> </g></svg> */}
            <svg className="w-64 mx-auto opacity-5" width="96.134mm" height="41.209mm" version="1.1" viewBox="0 0 96.134 41.209" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-1.7652 -52.35)">
            <g transform="matrix(.30911 0 0 .26458 1.147 51.46)">
            <rect x="2" y="3.365" width="311" height="155.75" fill="#008afc"/>
            </g>
            <g transform="matrix(.25167 0 0 .24997 5.1651 -112.08)">
            <rect x="108.32" y="662.34" width="155.75" height="155.75" fill="#fff"/>
            </g>
            <g transform="matrix(.26458 0 0 .26458 -133.23 -103.76)">
            <g transform="matrix(133.33,0,0,133.33,522.22,714.07)">
                <path d="m0.45-0.7v0.38l-0.27-0.38h-0.12v0.7h0.16v-0.38l0.27 0.38h0.12v-0.7z" fill="#fff"/>
            </g>
            </g>
            <g transform="matrix(.26458 0 0 .26458 -62.497 -103.63)">
            <g transform="matrix(133.33,0,0,133.33,522.22,714.07)">
                <path d="m0.53 0-0.179-0.254 0.173-0.246h-0.172l-0.087 0.124-0.087-0.124h-0.172l0.173 0.246-0.179 0.254h0.172l0.093-0.132 0.093 0.132z" fill="#fff"/>
            </g>
            </g>
            <g transform="matrix(.39277 0 0 .39277 -65.01 -246.18)">
            <g transform="matrix(89.818,0,0,89.818,253.83,843.95)">
                <path d="m0.193-0.7-0.183 0.066 0.039 0.137 0.124-0.034v0.531h0.16v-0.7z" fill="#008afc"/>
            </g>
            <g transform="matrix(89.818,0,0,89.818,290.03,843.95)">
                <path d="m0.57-0.26h-0.07v-0.15h-0.162v0.15h-0.146l0.223-0.44h-0.172l-0.223 0.44v0.148h0.318v0.112h0.162v-0.112h0.07z" fill="#008afc"/>
            </g>
            </g>
            <g stroke="#000" strokeWidth="2.0467">
            <path transform="matrix(.26458 0 0 .26458 1.7652 52.35)" d="m0 77.709v-77.709h362.82v155.42h-362.82zm264.26 0v-73.918h-148.38v147.84h148.38zm-222.44 21.966 0.14079-24.876 35.199 49.705 16.516 0.0466v-94.248l-21.932 0.29319-0.54152 49.14-35.094-49.411h-16.622v46.752c0 25.713 0.16246 46.914 0.36102 47.113 0.19856 0.19856 5.1917 0.36102 11.096 0.36102h10.735zm266.67 16.504c3.2196-4.6043 5.9799-8.3815 6.134-8.3936 0.1541-0.0122 2.9249 3.755 6.1573 8.3715l5.8771 8.3936h23.789l-1.8632-2.5722c-7.3945-10.209-21.689-30.835-21.692-31.301-2e-3 -0.3133 4.9097-7.5398 10.915-16.059s11.067-15.878 11.249-16.353c0.29699-0.77394-0.87342-0.86364-11.269-0.86364h-11.6l-5.651 8.1161c-3.1081 4.4639-5.7729 8.109-5.9218 8.1004-0.14892-0.0087-2.7852-3.6609-5.8583-8.1161l-5.5875-8.1004h-11.664c-9.389 0-11.611 0.13825-11.392 0.70887 0.14962 0.38988 5.3041 7.8483 11.454 16.574l11.182 15.865-0.93279 1.4315c-0.51303 0.78732-5.8749 8.4374-11.915 17l-10.982 15.569h23.717z"/>
            <path transform="matrix(.26458 0 0 .26458 1.7652 52.35)" d="m147.84 89.081c0-22.751-0.18989-35.47-0.52954-35.47-0.29125 0-3.9926 0.96927-8.2253 2.1539-4.2326 1.1847-7.7508 2.0985-7.8182 2.0307-0.27756-0.27939-5.0602-17.389-4.9044-17.545 0.0932-0.09324 5.5654-2.1208 12.16-4.5056l11.991-4.3361h17.904v93.142h-20.578z"/>
            <path transform="matrix(.26458 0 0 .26458 1.7652 52.35)" d="m223.65 116.97v-7.5813h-42.78v-18.928l29.944-59.051h11.021c6.0616 0 11.021 0.1041 11.021 0.23133s-6.5795 13.196-14.621 29.041-14.621 28.9-14.621 29.011c0 0.11075 4.5082 0.20136 10.018 0.20136h10.018v-19.495h21.119v19.495h9.2059v19.495h-9.2059v15.163h-21.119z"/>
            </g>
            </g>
            </svg>

        </div>
    );
}