import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { isActiveCandidateUser } from "../../../../lib/user";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import { useEffect, useState } from "react";
import { Navigation } from "../../common/navigation";
import AuthLoading  from "../../common/authLoading";
import { Role, deleteRole, getRoles } from "../../../../features/candidates/candidatesSlice";
import RolesListLoading from "./loading";
import RolesListError from "./error";
import { BreadcrumbItem, Breadcrumbs, Button, getKeyValue, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, useDisclosure } from "@nextui-org/react";
import moment from 'moment';
import HomeIcon from "../../common/icons/homeIcon";
import RolesIcon from "../common/rolesIcon";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../common/icons/plusIcon";
import EditIcon from "../../common/icons/editIcon";
import DeleteIcon from "../../common/icons/deleteIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";

export function RolesList() {

    const { keycloak, initialized } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const roles = useAppSelector(state => state.candidates.roles)
    const getRolesStatus = useAppSelector(state => state.candidates.getRolesStatus)

    const deleteRoleStatus = useAppSelector(state => state.candidates.deleteRoleStatus)

    const {isOpen, onOpen, onOpenChange} = useDisclosure()
    const [ selectedRole, setSelectedRole ] = useState('')

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    const loaded = () => { return getProfileStatus === 'idle' && getRolesStatus === 'idle' }
    const loading = () => { return getProfileStatus === 'loading' || getRolesStatus === 'loading' }
    const failed = () => { return getProfileStatus === 'failed' || getRolesStatus === 'failed' }

    const cellValue = (item: Role, key: any) => {

      switch(key) {
        case 'company':
        case 'title':
        case 'industry':
          return getKeyValue(item, key).name

        case 'startDate':
          var startDateValue = getKeyValue(item, key);
          return startDateValue ? moment(startDateValue).format('Do MMMM, YYYY') : ''

        case 'endDate':
          var endDateValue = getKeyValue(item, key);
          return endDateValue ? moment(endDateValue).format('Do MMMM, YYYY') : 'Current role'

        case 'actions': 
          return (
            <span className="flex">
              <Tooltip content="Edit role" placement="left">
                <span className="text-lg pr-4 text-default-400 cursor-pointer active:opacity-50" onClick={() => navigate(`/edit-role/${item.uuid}`)}>
                  <EditIcon />
                </span>
              </Tooltip>
              <Tooltip content="Delete role" placement="right">
                <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => { setSelectedRole(item.uuid); onOpen(); }}>

                  <DeleteIcon />
                </span>
              </Tooltip>
            </span>
          )

        default: 
          return getKeyValue(item, key)
      }
    }

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {
        console.log('a');
        if(!profile) {
          console.log('profile not there');
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            .then((response) => {
              dispatch(getRoles({token: keycloak.token, uuid: response.payload.uuid}))
            })
        } else if(!roles || roles.length === 0) {
          console.log("roles")
          dispatch(getRoles({token: keycloak.token, uuid: profile.uuid}))
        } else {
          console.log(roles);
          console.log('x');
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])
    

    return initialized && keycloak.authenticated ? (

      <React.Fragment>
        {loading() && (
          <RolesListLoading />
        )}
        {failed() && (
          <RolesListError />
        )}
        {loaded() && isActiveCandidateUser(keycloak, profile, roles) && (
      
          <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            {/* <RolesListLoading /> */}

            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
              <ModalContent>
                  {(onClose) => (
                      <>
                      <ModalHeader className="flex flex-col gap-1">Delete this role?</ModalHeader>
                      <ModalBody>
                          <p> 
                              This will permanently delete the role, and cannot be reversed.
                          </p>
                      </ModalBody>
                      <ModalFooter>
                          <Button variant="light" onPress={() => { setSelectedRole(''); onClose() }}>
                            Keep it
                          </Button>
                          <Button 
                              color="danger" 
                              onPress={() => {
                                dispatch(deleteRole({token: keycloak.token, profileId: profile?.uuid, uuid: selectedRole}))
                                  .then((response) => {
                                      if(response.meta.requestStatus === 'fulfilled') {
                                        setSelectedRole('');
                                      }
                                      if(response.meta.requestStatus === 'fulfilled' || response.meta.requestStatus === 'rejected') {
                                        onClose()
                                      }
                                  })
                              }
                          }>
                            Delete role
                            {(deleteRoleStatus === 'loading') && <Spinner size="sm" color="default" />}  
                            {(deleteRoleStatus !== 'loading') && <RightArrowIcon />}
                          </Button>
                      </ModalFooter>
                      </>
                )}
              </ModalContent>
            </Modal>

            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">

              <div className="flex flex-wrap mb-11">
                <div className="w-full sm:w-10/12">
                  <Breadcrumbs className="sm:mt-3">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/roles')}><RolesIcon />My roles</BreadcrumbItem>
                    <BreadcrumbItem>List</BreadcrumbItem>
                  </Breadcrumbs>
                </div>
                <div className="w-full mt-8 sm:mt-0 sm:w-2/12 flex sm:justify-end">
                  <Button color="success" className="text-white" onClick={() => navigate('/add-role')} endContent={<PlusIcon />}>Add role</Button>
                </div>
              </div>

              <Table
                aria-label="Example table with client side sorting"
                isStriped
              >
                <TableHeader>
                  <TableColumn key="company">Company</TableColumn>
                  <TableColumn key="title">Title</TableColumn>
                  <TableColumn key="startDate">Start date</TableColumn>
                  <TableColumn key="endDate">End date</TableColumn>
                  <TableColumn key="actions">Actions</TableColumn>
                </TableHeader>
                <TableBody 
                  items={roles} 
                  emptyContent={"No roles to display."}
                >
                  {(item) => (
                    <TableRow key={item.uuid}>
                      {(columnKey) => <TableCell>{cellValue(item, columnKey)}</TableCell>}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </React.Fragment>
    ) : <AuthLoading />
}
