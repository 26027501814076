import Keycloak from 'keycloak-js'
import { Profile } from '../features/profiles/profilesSlice'
import { Role } from '../features/candidates/candidatesSlice'

export const isCandidateUser = (keycloak: Keycloak) : boolean => {
  return keycloak?.tokenParsed?.realm_access?.roles.find((obj, key) => obj.toLocaleLowerCase() === 'candidate') !== undefined
}

export const isAdminUser = (keycloak: Keycloak) : boolean => {
  return keycloak?.tokenParsed?.realm_access?.roles.find((obj, key) => obj.toLocaleLowerCase() === 'admin') !== undefined
}

export const canManageCandidates = (keycloak: Keycloak) : boolean => {
  return keycloak?.tokenParsed?.realm_access?.roles.find((obj, key) => obj.toLocaleLowerCase() === 'admin_manage_candidates') !== undefined
}

export const isCompanyUser = (keycloak: Keycloak) : boolean => {
  return keycloak?.tokenParsed?.realm_access?.roles.find((obj, key) => obj.toLocaleLowerCase() === 'company') !== undefined
}

export const hasSelectedUserType = (keycloak: Keycloak) : boolean => {
  return isCandidateUser(keycloak) || isCompanyUser(keycloak)
}

export const invitationCodeRequired = (keycloak: Keycloak) : boolean => {
  return isCompanyUser(keycloak) || (isCandidateUser(keycloak) && process.env.REACT_APP_CANDIDATE_INVITATION_REQUIRED === 'true')
}

export const isActiveCandidateUser = (keycloak: Keycloak, profile: Profile | null, roles: Role[]) : boolean => {

  if(!isCandidateUser(keycloak)) {
      return false;
  }

  if(!profile) { 
      return false;
  }

  return (profile?.invited || !invitationCodeRequired(keycloak)) && profile?.completed && profile?.candidateProfile?.inducted;
}

export const isCompleteCandidateUser = (keycloak: Keycloak, profile: Profile | null, roles: Role[]) : boolean => {

  if(!isCandidateUser(keycloak)) {
      return false;
  }

  if(!profile) { 
      return false;
  }

  return (profile?.invited || !invitationCodeRequired(keycloak)) && profile?.completed && (roles.length > 0) && profile?.candidateProfile?.inducted;
}


